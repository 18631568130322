import React, { PureComponent } from "react";
import { get, size, isEmpty, isArray } from "lodash";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openDB } from "idb";
import {SCREENS, STATUS} from "../constant";
import { getGameDetail, getQuestionBank, getLeaderBoard, postSocial } from "../actions/common.action";
import { ReactComponent as Loader } from "../assets/images/spinner-loader.svg";
import  LogoSvg from "../assets/images/AO_NewLogo.svg";
import { getLocalstorage, clearAllData } from "../helper";
import GlobalConfig from "../globalConfig";


class AppComponent extends PureComponent {
  state = {
    gameName: "",
    gameDescription: ""
  }
  async componentDidMount(){
    const { getGameDetailDispatch, getQuestionBankDispatch, gamesList, questionsBank, location } = this.props;
    let queryParams= new URLSearchParams(location.search);
    let queryHash= new URLSearchParams(location.hash.substr(location.hash.indexOf('?')));
    const uid = queryParams.get('uid');
    const socialPlatform = queryParams.get('social');
    const uidHash = queryHash.get('uid');
    const socialPlatformHash = queryHash.get('social');
    if((uid && socialPlatform) || (uidHash && socialPlatformHash)){
      postSocial(undefined, {combinationKey: (uid || uidHash), value: (socialPlatform || socialPlatformHash)})
    }

    if(isEmpty(questionsBank)){
      getQuestionBankDispatch(undefined, {Type: "INIT" });
    }
    else{
      const lastSyncDate = getLocalstorage("questionLastSync");
      getQuestionBankDispatch(undefined, {Type: "SYNC", lastSyncDate});
    }

    if(!size(gamesList)){
      getGameDetailDispatch(undefined, {Type:"INIT"})
    }
    else{
      const lastSyncDate = getLocalstorage("gameLastSync");
      getGameDetailDispatch(undefined, {Type:"SYNC", lastSyncDate})
    }

    await openDB('users', 1, {
      upgrade(db) {
        db.createObjectStore('scores', {
          autoIncrement: true
        });
      }
    });
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const { activeGame, getLeaderBoardDispatch, leaderBoardListStatus } = nextProps;
    if(
      activeGame
      && activeGame.GameId
      && leaderBoardListStatus !== STATUS.LOADING
      && leaderBoardListStatus !== STATUS.SUCCESS
    ){

      getLeaderBoardDispatch(undefined, { gameId: activeGame.GameId}).then(res =>{
        if(isArray(res.data)){
          clearAllData()
        }
      });
      const image = GlobalConfig.getApiUrlFromRoot(`/${get(activeGame, "Image")}`);
      document.querySelector('body').style.backgroundImage = `url(${encodeURI(image)})`;
      return{
        gameName: get(activeGame, "Title"),
        gameDescription: get(activeGame, "Description")
      }
    }
    else {
      return{
        gameName: get(activeGame, "Title", ""),
        gameDescription: get(activeGame, "Description", "")
      }
    }
  }
  navigate = () => {
    const { history } = this.props;
    history.push(SCREENS.REGISTRATION);
  }
  render(){
    const { gameListApiStatus, questionApiStatus, activeGame } = this.props;
    const { gameDescription,  } = this.state;
    const logoImageUrl = get(activeGame, "GameLogo");
    const activeGameLogo = logoImageUrl ?  GlobalConfig.getApiUrlFromRoot(`/${logoImageUrl}`) : LogoSvg;
    if(gameListApiStatus === STATUS.LOADING || questionApiStatus === STATUS.LOADING || !questionApiStatus){
      return <div className="loader-container"><Loader /></div>
    }
    return (
      <section className="wrapper start_page">
        <h2 className="heading">
          <div className="logo-container">
            <img src={activeGameLogo} alt="Game Logo" />
          </div>
        </h2>
        <span className="sub-heading">{gameDescription}</span>
        <div className="text-center">
          <button className="btn" onClick={this.navigate}>start</button>
        </div>
      </section>
    );
  }
}

AppComponent.propTypes = {
  getGameDetailDispatch: PropTypes.func,
  getQuestionBankDispatch: PropTypes.func,
  leaderBoardList: PropTypes.arrayOf(PropTypes.shape({})),
  questionsBank: PropTypes.shape({}),
  gamesList: PropTypes.arrayOf(PropTypes.shape({}))
};

AppComponent.defaultProps = {
  getGameDetailDispatch: () => {},
  getQuestionBankDispatch: () => {},
  leaderBoardList: [],
  questionsBank: {},
  gamesList: []
};

function mapStateToProps(state) {
  return {
    gameListApiStatus: state.commonReducer.gameListApiStatus,
    questionApiStatus: state.commonReducer.questionApiStatus,
    activeGame: state.commonReducer.activeGame,
    leaderBoardList: state.commonReducer.leaderBoardList,
    leaderBoardListStatus: state.commonReducer.leaderBoardListStatus,
    questionsBank: state.commonReducer.questionsBank,
    gamesList: state.commonReducer.gamesList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getGameDetailDispatch: getGameDetail,
      getQuestionBankDispatch: getQuestionBank,
      getLeaderBoardDispatch: getLeaderBoard
    },
    dispatch
  );
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppComponent));

