import React, { useState } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { SCREENS } from "../constant";
import { setLocalstorage, getLocalstorage } from "../helper";


const Difficulty = ({ activeGame, history }) => {
  const [difficulty, setDifficulty] = useState("");
  const setDifficultyLevel = level => {
    setDifficulty(level);
  }
  const navigateToNextPage = () => {
    setLocalstorage("difficulty", difficulty);
    history.push(SCREENS.GAME);
  }
  const playerInfo = getLocalstorage("playerInfo");
  if(!playerInfo){
    history.push("/");
    return null;
  }

  return (
    <section className="difficulties v-center text-center">
      <h2>CHOOSE DIFFICULTY</h2>
      <ul>
        {(activeGame || []).GameRules.map(game => (
          <li key={game.DifficultyLevelId} className={difficulty === game.DifficultyLevelId ? "active": ""}>
            <button className="no-style" onClick={() => setDifficultyLevel(game.DifficultyLevelId)}>{game.DifficultyLevel}</button>
          </li>
        ))}
      </ul>
      <p>Difficulty level influences score - the more difficult the question, the more points up for grabs! Can you top the leaderboard?</p>
      <button className="btn" disabled={!difficulty} onClick={navigateToNextPage}>Next</button>
    </section>
  );
};

Difficulty.propTypes = {
  activeGame: PropTypes.shape({}),
  history: PropTypes.shape({})
};

Difficulty.defaultProps = {
  activeGame: null,
  history: {}
};

function mapStateToProps(state) {
  return {
    activeGame: state.commonReducer.activeGame
  };
}

export default connect(mapStateToProps)(withRouter(Difficulty));
