import HttpError from "standard-http-error";
import { get } from "lodash";
import { serialize, getLocalstorage } from "../helper";

export const successResponse = response => ({
  status: response.status,
  data: response
});

export const throwHttpError = error => {
  if (error && error.response ){
  if (error.response.data) {
    throw new HttpError(
      get(error, "response.data.status.code"),
      get(error, "response.data.status.message"),
      {
        data: error.response.data
      }
    );
    }
    throw new HttpError(error.response.status, error.response.statusText);
  }
  else{
    throw new HttpError(500,  'Unknown error')
  }
};

export default async (request, httpService = fetch) => {
  const { method, data, headers } = request;
  let { url } = request;
  const location = getLocalstorage("location");
  if (get(location, "PlatFormCode")) {
    data.PlatFormCode = location.PlatFormCode;
  }
  if (method === "GET") {
    if (data) {
      url += `?${serialize(data)}`;
    }
  }
  return httpService(url, {
      method,
      headers: Object.assign({}, headers),
    ...( method.toLowerCase() === 'post' && {body: JSON.stringify(data)})
    }).then(res =>  res.json())
    .then(successResponse, error => {
      throwHttpError(error);
    });
};
