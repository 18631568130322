import React from "react";
import { Route } from "react-router-dom";
import { SCREENS } from "./constant";
import Home from "./containers/home.container";
import Location from "./containers/locationSetup.container";
import Registration from "./containers/registration.container";
import Difficulty from "./containers/difficultylevel.container";
import Game from "./containers/game.container";
import Leaderboard from "./containers/leaderboard.container";
import { removeItemFromLocalStorage } from "./helper";

const Routes = ( ) => {
  removeItemFromLocalStorage("difficulty");
  removeItemFromLocalStorage("playerInfo");
  return(
    <>
      <Route exact path={"/"} component={Home} />
      <Route exact path={"/setup"} component={Location} />
      <Route exact path={SCREENS.REGISTRATION} component={Registration} />
      <Route exact path={SCREENS.DIFFICULTY} component={Difficulty} />
      <Route exact path={SCREENS.GAME} component={Game} />
      <Route exact path={SCREENS.SCORE} component={Leaderboard} />
    </>
  )
}

export default Routes;
