import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from './serviceWorker';
import store from './store';
import "./assets/css/style.scss";
import Routes from "./routes";

const NODE_ENV = process.env.REACT_APP_HOST_ENV || 'dev';

if (NODE_ENV !== 'dev') {
  //do Something here, fetch config from server
  //then do bootstrap
  bootstrapApp();
} else {
  bootstrapApp();
}

function bootstrapApp() {
  ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
        <Routes/>
    </HashRouter>
  </Provider>, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
}
