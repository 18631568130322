import React, { useState } from 'react';
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isValidateEmail, setLocalstorage, onlyNumbers } from "../helper";
import { SCREENS, STATUS } from "../constant";

function mapStateToProps(state) {
  return {
    gameListApiStatus: state.commonReducer.gameListApiStatus
  };
}

export default connect(mapStateToProps)(withRouter(({history, gameListApiStatus}) => {
  if(gameListApiStatus !== STATUS.SUCCESS){
    history.push("/");
  }
  const [name, setName] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [email, setEmail] = useState("");
  const [error, setEmailError] = useState(false);
  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [dobError, setDobError] = useState(false);

  const emailUpdate = ({target})=> {
    const { value } = target;
    setEmail(target.value);
    if(!value){
      setEmailError(false);
      return false;
    }
    if(!isValidateEmail(value)){
      setEmailError(true);
    }
    else{
      setEmailError(false);
    }
  }

  const handleDob = (value, callBack) => {
    callBack(onlyNumbers(value));
    setDobError(false);
  }

  const navigateToNextPage = () => {
    const dob = moment(`${year}-${month}-${date}`);
    if(dob.isValid() && dob.isBefore(new Date()) && moment().diff(dob,"year") >= 18){
      setLocalstorage("playerInfo", { name, zipCode, dob: (year + month + date), email });
      history.push(SCREENS.DIFFICULTY);
    }
    else{
      setDobError(true);
    }
  }
  return (
    <section className="wrapper player_details margin-top40">
      <h2>ENTER PLAYER DETAILS</h2>
      <form>
        <ul className="registration">
          <li>
            <label>Name <span>*</span></label>
            <input type="text" placeholder="Enter your name" onChange={({target}) => setName(target.value)} />
          </li>
          <li>
            <label>DOB <span>*</span></label>
            <div className="dob-container">
              <div className="dop-input">
                <input
                    type="tel"
                    placeholder="Month"
                    maxLength={2}
                    value={month}
                    onChange={({target}) => handleDob(target.value, setMonth)}
                />
              </div>
              <div className="dop-input">
                <input
                  type="tel"
                  placeholder="Date"
                  maxLength={2}
                  value={date}
                  onChange={({target}) => handleDob(target.value, setDate)}
                />
              </div>
              <div className="dop-input">
                <input
                  type="tel"
                  placeholder="Year"
                  maxLength={4}
                  value={year}
                  onChange={({target}) => handleDob(target.value, setYear)}
                />
              </div>
            </div>
            <span className="dob-error">{dobError? "DOB is not valid" : null}</span>
          </li>
          <li>
            <label>Zipcode <span>*</span></label>
            <input
              maxLength={5}
              type="tel"
              placeholder="Enter zipcode"
              value={zipCode}
              onChange={({target}) => setZipcode(onlyNumbers(target.value))} />
          </li>
          <li>
            <label>Email (Optional)</label>
            <input type="text" value={email} placeholder="Enter your email" onChange={emailUpdate} />
            <span className="email-error">{error? "Email is not valid" : null}</span>
          </li>
        </ul>
      </form>
      <button className="btn" onClick={navigateToNextPage} disabled={error || !name || !zipCode || dobError || !date || !month || !year }>Play</button>
    </section>
  );
}))
