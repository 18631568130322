const apiNetworkInterface = {
  dev: "http://182.71.160.190/BostonBeerMockUAT",
  staging: "http://182.71.160.190/BostonBeerMockUAT",
  qa: "https://trivia.angryorchard.com/api"
};

const BOSTON_BEER = {
  API_ROOT:
    apiNetworkInterface[process.env.REACT_APP_ENV] || apiNetworkInterface.dev
  ,
  getApiUrlFromRoot(url) {
    return this.API_ROOT + url;
  },
  getHeaders(headers) {
    const HEADERS = {
      JSON: {
        "Content-Type": "application/json"
      },
      URL_ENCODED: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      MULTIPART_FORM_DATA: {
        "Content-Type": "multipart/form-data"
      },
      TEXT_HTML: {
        "Content-Type": "text/html"
      }
    };
    function getHeaders() {
      let headerObj = [];
      headers.forEach(item => {
        const header = HEADERS[item];
        headerObj = Object.assign(headerObj, header);
      });
      return headerObj;
    }
    return getHeaders();
  }
};

export default BOSTON_BEER;
