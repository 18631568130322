export const LOGIN_ACTION = {
  LOGIN_STARTED: "LOGIN_STARTED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED"
}

export const GAME_ACTION = {
  GET_GAME_DETAIL_START: "GET_GAME_DETAIL_START",
  GET_GAME_DETAIL_SUCCESS: "GET_GAME_DETAIL_SUCCESS",
  GET_GAME_DETAIL_FAILURE: "GET_GAME_DETAIL_FAILURE",
  GET_LEADERBOARD_DETAIL_START: "GET_LEADERBOARD_DETAIL_START",
  GET_LEADERBOARD_DETAIL_SUCCESS: "GET_LEADERBOARD_DETAIL_SUCCESS",
  GET_LEADERBOARD_DETAIL_FAILURE: "GET_LEADERBOARD_DETAIL_FAILURE",
  CHANGE_LEADERBOARD_API_STATUS: "CHANGE_LEADERBOARD_API_STATUS",
}

export const QUESTION_ACTION = {
  GET_QUESTION_BANK_START: "GET_QUESTION_BANK_START",
  GET_QUESTION_BANK_SUCCESS: "GET_QUESTION_BANK_SUCCESS",
  GET_QUESTION_BANK_FAILURE: "GET_QUESTION_BANK_FAILURE"
}

export const STATUS = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  LOADING: "LOADING"
};

export const SCREENS = {
  REGISTRATION: "/registration",
  DIFFICULTY: "/difficulty",
  GAME: "/game",
  SCORE: "/leaderboard",
  HOME: "/"
}

export const SCORE_TABLE = {
  1: 20,
  2: 19,
  3: 18,
  4: 17,
  5: 16,
  6: 15,
  7: 14,
  8: 13,
  9: 12,
  10: 11,
  11: 10,
  12: 9,
  13: 8,
  14: 7,
  15: 6,
  16: 5,
  17: 4,
  18: 3,
  19: 1
}
