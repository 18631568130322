import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { size, get } from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { changeLeaderboardApiStatus, shareEmail } from "../actions/common.action";
import {getLocalstorage, isValidateEmail, readFromIndexDB} from "../helper";

class LeaderBoard extends PureComponent {
  state = {
    scores: [],
    email: "",
    emailError: "",
    emailSent: false
  }
  startNewGame = () => {
    const { history } = this.props;
    history.push("/");
  }

  async componentDidMount() {
    const { history } = this.props;
    const difficultyLevel = getLocalstorage("difficulty");
    const playerInfo = getLocalstorage("playerInfo");
    if (!difficultyLevel || !playerInfo) {
      history.push("/");
      return
    }
    try {
      const scores = await readFromIndexDB();
      this.setState({
        scores,
        email: playerInfo.email
      })
    }
    catch (e) {
      console.log("e", e)
    }

  }

  componentWillUnmount() {
    const { changeLeaderboardApiStatusDispatch } = this.props;
    changeLeaderboardApiStatusDispatch();
  }

  handleEmail = ({target})=> {
    const { value } = target;
    this.setState({
      email: value
    });
    if(!isValidateEmail(value)){
      this.setState({
        emailError: true
      })
    }
    else{
      this.setState({
        emailError: false
      })
    }
  }

  shareEmail = async () => {
    const { email } = this.state;
    const { history } = this.props;
    this.setState({
      emailSent: true
    })
    const params = {
      combinationKey: get(history, "location.uid"),
      toEmail: email,
      userName: get(history, "location.userName"),
      Score: get(history, "location.score")
    }
    await shareEmail(undefined, params);

  }

  render() {
    const { leaderBoardList, history } = this.props;
    const { location } = history;
    const { scores, email, emailError, emailSent } = this.state;
    const playerInfo = getLocalstorage("playerInfo");
    const newLeaderBoard = [...leaderBoardList, ...scores.map((item) => ({ UsrName: item.UsrName, Score: item.Score }))]
      .sort((a, b) => b.Score - a.Score)
      .slice(0, 10);
    return (
      <>
        <section className="wrapper scores">
          <h3>Your Score!</h3>
          <span className="score_board">{get(location, "score")}</span>
          <p>Share your score! Let your friends know!</p>
          {/* <div className="social_media">
          <span className="facebook"></span>
          <span className="twitter"></span>
        </div> */}
          {emailSent ? <div className="email-send">Email has been sent</div> :
            <div className="share-email row">
              <div className="col-md-8">
                <input type="text"
                       className="email_textbox"
                       value={email}
                       disabled={!!get(playerInfo, "email", "")}
                       onChange={this.handleEmail}
                       placeholder="Enter your email"
                />
                <span className="email-error">{emailError ? "Email is not valid" : null}</span>
              </div>
              <div className="col-md-4">
                <button className="btn share-email-btn" onClick={this.shareEmail} disabled={!email || emailError}>Share</button>
              </div>
            </div>
          }

        </section>
        {size(newLeaderBoard) ?
          <div className="wrapper leaderboard-container">
            <h2>LEADERBOARD</h2>
            <ul className="leaderboard">
              {newLeaderBoard.map((data, index) => {
                return (
                  <li key={index}><span>{data.UsrName.substr(0, 3)}</span><span>{data.Score}</span></li>
                )
              })}
            </ul>
          </div>
          : null
        }
        <div className="text-center">
          <button className="btn" onClick={this.startNewGame}>start new game</button>
        </div>
      </>

    )
  }
}

LeaderBoard.propTypes = {
  leaderBoardList: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({}),
  changeLeaderboardApiStatusDispatch: PropTypes.func
};

LeaderBoard.defaultProps = {
  leaderBoardList: [],
  history: {},
  changeLeaderboardApiStatusDispatch: () => { }
};

function mapStateToProps(state) {
  return {
    leaderBoardList: state.commonReducer.leaderBoardList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeLeaderboardApiStatusDispatch: changeLeaderboardApiStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeaderBoard));
