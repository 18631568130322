import React, { PureComponent }  from "react";
import { get } from "lodash";
import { Button } from "../components";
import { getLocation } from "../actions/common.action";
import { setLocalstorage } from "../helper";

export default class LocationSetup extends PureComponent{
  state = {
    selectedLocation: null,
    optionList: [],
    message: ""
  }

  async componentDidMount(){
    const response = await getLocation(undefined);
    const data = get(response, "data");
    if(data){
       this.setState({
         optionList: data
         //.filter(option => option.PlatformId !==1)
       })
    }
  }

  handleSelectChange = (e) => {
    const { optionList } = this.state;
    const { value } = e.target;
    this.setState({
      selectedLocation: optionList.find(option => parseInt(option.PlatformId, 10) === parseInt(value))
    })
  }

  saveLocation = () => {
    const { selectedLocation } = this.state;
    setLocalstorage("location", selectedLocation);
    this.setState({
      message: "Saved Successfully!"
    })

  }

  render() {
    const { optionList, selectedLocation, message } = this.state;
    return (
      <div className="location-setup">
        <h4>Select Location from dropdown</h4>
        <select className="location-select" onChange={this.handleSelectChange}>
          <option value="">Select Location</option>
          {optionList.map(option => {
            return (
              <option key={option.PlatFormCode} value={option.PlatformId}>{option.PlatformType}</option>
            )
          })}
        </select>
        <div className="text-center">
          <div style={{marginBottom: "5px", color: "green", height: "30px", lineHeight: "30px"}}>{message}</div>
          <Button disabled={!selectedLocation} onClick={this.saveLocation}>Submit</Button>
        </div>
      </div>
    )
  }
}
