import request from "../services/request";
import * as apiService from "../services/apiService";
import { GAME_ACTION, QUESTION_ACTION } from "../constant";
import { setLocalstorage } from "../helper";

export const getLocation = (requestMethod = request, params) => {
  return requestMethod(apiService.getLocationParams(params))
    .then(res => {
      return res;
    })
    .catch(e => {
      console.log(e);
    });
};

export const getGameDetail = (requestMethod = request, params) => {
  return function(dispatch){
    dispatch({
      type: GAME_ACTION.GET_GAME_DETAIL_START
    });
    return requestMethod(apiService.getGameDetail(params))
      .then(res => {
        if(window.navigator && window.navigator.isOnline){
          setLocalstorage("gameLastSync", new Date().getTime());
        }
        dispatch({
          type: GAME_ACTION.GET_GAME_DETAIL_SUCCESS,
          payload: res.data
        });
        return res;
      })
      .catch(e => {
        console.log(e);
      });
  }
};

export const getQuestionBank = (requestMethod = request, params) => {
  return function(dispatch){
    dispatch({
      type: QUESTION_ACTION.GET_QUESTION_BANK_START
    });
    return requestMethod(apiService.getQuestionBank(params))
      .then(res => {
        if(window.navigator && window.navigator.isOnline){
          setLocalstorage("questionLastSync", new Date().getTime());
        }
        dispatch({
          type: QUESTION_ACTION.GET_QUESTION_BANK_SUCCESS,
          payload: res.data
        });
        return res;
      })
      .catch(e => {
        console.log(e);
      });
  }
};

export const postPlayerScore = (requestMethod = request, params) => {
  return requestMethod(apiService.postPlayerScore(params))
    .then(res => {
      return res;
    })
    .catch(e => {
      console.log(e);
    });
};

export const getLeaderBoard = (requestMethod = request, params) => {
  return function(dispatch){
    dispatch({
      type: GAME_ACTION.GET_LEADERBOARD_DETAIL_START
    });
    return requestMethod(apiService.getLeaderBoardParams(params))
      .then(res => {
        dispatch({
          type: GAME_ACTION.GET_LEADERBOARD_DETAIL_SUCCESS,
          payload: res.data
        });
        return res;
      })
      .catch(e => {
        console.log(e);
      });
  }
};

export const changeLeaderboardApiStatus = () => {
  return function(dispatch){
    dispatch({
      type: GAME_ACTION.CHANGE_LEADERBOARD_API_STATUS
    });
  }
};

export const postSocial = (requestMethod = request, params) => {
  return requestMethod(apiService.postSocialShare(params))
    .then(res => {
      return res;
    })
    .catch(e => {
      console.log(e);
    });
};

export const shareEmail = (requestMethod = request, params) => {
  return requestMethod(apiService.shareEmail(params))
    .then(res => {
      return res;
    })
    .catch(e => {
      console.log(e);
    });
};

