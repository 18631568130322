import React, { PureComponent }  from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get, size } from "lodash";
import IdleTimer from "react-idle-timer";
import { withRouter } from "react-router-dom";
import { openDB } from "idb";
import { CSSTransition } from 'react-transition-group';
import  CorrecAnswerSvg  from "../assets/images/right.svg";
import { getLocalstorage, dateTimeStamp, dateFormatMapping } from "../helper";
import GlobalConfig from '../globalConfig';
import { SCREENS, SCORE_TABLE } from "../constant";
import { postPlayerScore } from "../actions/common.action"
import { LoaderOverlay } from "../components";




class Game extends PureComponent{

  state = {
    gameQuestions: [],
    open: false,
    activeQuestionNo: 1,
    quesCount: 0,
    answers: {},
    difficultyLevelWeightage: 0,
    loader: false,
    imageError: false,
    modalVisible: false
  }

  startTime = null;
  totalScore = 0;

  componentDidMount(){
    const { questionsBank, activeGame, history } = this.props;
    const difficultyLevel = getLocalstorage("difficulty");
    const playerInfo = getLocalstorage("playerInfo");
    if(!difficultyLevel || !playerInfo) {
      history.push("/");
      return
    }
    const { GameRules } = activeGame;
    const difficultyRules = (GameRules || []).find(game => game.DifficultyLevelId === difficultyLevel);
    const rules = get(difficultyRules, "GameRuleDesc", []);
    const gameQuestions = [];
    rules.forEach(({ DifficultyLevelId, NoOfQues }) => {
      const questionSet = questionsBank[DifficultyLevelId].filter((question) => question.BrandId === activeGame.BrandId);
      for(let i =1; i <= questionSet.length;i++ ){
        const question = this.getUniqueQuestion(gameQuestions, questionSet);
        gameQuestions.push(question);
      }
    })
    this.setState({
      gameQuestions,
      quesCount: activeGame.QuesCount,
      difficultyLevelWeightage: get(difficultyRules, "DifficultyLevelWeightage", 0)
    })
    this.startTime = dateTimeStamp();
    const image = GlobalConfig.getApiUrlFromRoot(`/${get(activeGame, "Image")}`);
    document.querySelector('.modal-popup').style.backgroundImage = `url(${encodeURI(image)})`;
  }

  getUniqueQuestion = (existingQuestion, questionSet) =>   {
    const question = questionSet[Math.floor(Math.random() * questionSet.length)];
    const isDuplicate = existingQuestion.includes(question);
    if(isDuplicate){
      return this.getUniqueQuestion(existingQuestion, questionSet)
    }
    return question;
  }

  setAnswer = answer => {
    const { answers } = this.state;
    const newAnswer = {};
    newAnswer[answer.QuestionId] = answer;
    this.setState({
      answers: {...answers, [`${answer.QuestionId}`]: {...answer}}
    });
  }

  onSubmit = questionId => {
    const { difficultyLevelWeightage } = this.state;
    const tableLength = Object.keys(SCORE_TABLE).length;
    const { answers, activeQuestionNo } = this.state;
    if(answers[questionId].submitted){
      this.startTime = dateTimeStamp();
      this.setState({
        activeQuestionNo: activeQuestionNo + 1
      });
      return
    }
    const endTime = dateTimeStamp();
    const totalSeconds = Math.abs(this.startTime - endTime) / 1000;
    const tableIndex = Math.ceil(totalSeconds / 5);
    const scoreRule = SCORE_TABLE[tableIndex];
    const scoreIndex = scoreRule || tableLength;
    answers[questionId].submitted = true;
    answers[questionId].startTime = this.startTime;
    answers[questionId].endTime = endTime;
    answers[questionId].totalSeconds = totalSeconds;
    if(answers[questionId].RightAnswer){
      answers[questionId].score = scoreIndex * difficultyLevelWeightage;
      this.totalScore += scoreIndex * difficultyLevelWeightage;
    }
    else{
      answers[questionId].score = 0;
    }

    this.setState({
      answers: {...answers}
    })
  }

  goToNext = async () => {
    const { answers } = this.state;
    const { activeGame } = this.props;
    const playerInfo = getLocalstorage("playerInfo");
    const difficultyLevel = getLocalstorage("difficulty");
    const answersArray = Object.keys(answers);
    const startTime = answers[answersArray[0]].startTime;
    const endTime = answers[answersArray[size(answersArray) - 1]].endTime;
    const uid = `${new Date().getTime().toString()}_${get(playerInfo, "dob")}`;
    const params = {
      userList:[{
      usrName: get(playerInfo, "name"),
      usrEmail: get(playerInfo, "email"),
      usrDOB: get(playerInfo, "dob"),
      usrZipCode: get(playerInfo, "zipCode"),
      usrPhone: get(playerInfo, "phone"),
      gameId: get(activeGame, "GameId"),
      difficultyLevelId :difficultyLevel,
      score: this.totalScore,
      timeInSec: Math.floor((Math.abs(startTime - endTime) / 1000)),
      noOfQuesAttempted: size(answersArray),
      startDateTime: dateFormatMapping(startTime),
      endDateTime: dateFormatMapping(endTime),
      combinationKey: uid
    }]
    }
    const { history } = this.props;
    this.setState({
      loader: true
    })
    try{
      const db = openDB('users');
      const isChrome = !!window.chrome  && (!!window.chrome.webstore || !!window.chrome.runtime || !!window.indexedDB);
      if(isChrome){
      db.then((x) => {
        var tx = x.transaction("scores", 'readwrite');
        var store = tx.objectStore("scores");
        store.put({Score: this.totalScore, UsrName: get(playerInfo, "name")});
      });
      await postPlayerScore(undefined, params);
    }
    }
    finally{
      this.setState({
        loader: false
      })
    }
    history.push({
      pathname:SCREENS.SCORE,
      score: this.totalScore,
      userName: get(playerInfo, "name"),
      uid
    });
  }

  onError = () => {
    this.setState({
      imageError: true
    })
  }

  onIdle = () => {
    this.setState({
      modalVisible: true
    })
  }

  closeModal = () => {
    this.setState({
      modalVisible: false
    })
  }

  startNewGame = () => {
    const { history } = this.props;
    this.setState({
      modalVisible: false
    }, () => {
      history.push("/");
    })
  }

  render() {
    const { gameQuestions, activeQuestionNo, answers, quesCount, loader, imageError, modalVisible } = this.state;
    const { leaderBoardList, activeGame } = this.props;
    const { QuestionId } = gameQuestions[activeQuestionNo -1] || {};
    const answer = answers[QuestionId];
    const alpha = ["a", "b", "c", "d", "e", "f", "g", "h"];
    const newLeaderBoard = [...leaderBoardList]
      .sort((a, b) => b.Score - a.Score)
      .slice(0, 10);
    const lastQuestion = activeQuestionNo === quesCount;
    const successIconUrl = get(activeGame, "CorrectAnswerIcon");
    let correctAnswerIcon = successIconUrl ? GlobalConfig.getApiUrlFromRoot(`/${successIconUrl}`)
                                          : CorrecAnswerSvg;
    return (
      <LoaderOverlay loading={loader}>
        <section className="wrapper questions">
        <div className="question-list--container">
          {gameQuestions.map((item, index) => {
            return(
              <CSSTransition
              in={activeQuestionNo === (index + 1)}
              key={item.QuestionId}
              timeout={500}
              classNames="item"
              unmountOnExit
            >
              <div className="question-view">
                <p>{item.Title}</p>
                <ul className="question-list">
                  {item.Answers.map((answer, index) => {
                    const isSubmitted = get(answers, `${item.QuestionId}.submitted`);
                    const selectedAnswer = get(answers, `${item.QuestionId}.AnswerID`);
                    const rightAnswer = get(answers, `${item.QuestionId}.RightAnswer`);
                    let wrongClassName = "";
                    let rightClassName = "";
                    let bgGreen = "";
                    let isCorrectAnswer=false;
                    if(answer.AnswerID === selectedAnswer && rightAnswer === false && isSubmitted){
                      wrongClassName = "wrong"
                    }
                    if(answer.AnswerID !== selectedAnswer && answer.RightAnswer && isSubmitted){
                      bgGreen = "green"
                    }

                    if(answer.AnswerID === selectedAnswer && answer.RightAnswer && isSubmitted){
                      rightClassName = "right"
                      isCorrectAnswer=true;
                    }
                    return (
                      <li className={wrongClassName || rightClassName || bgGreen } key={answer.AnswerID}>
                        {
                          isCorrectAnswer ? <style dangerouslySetInnerHTML={{
                            __html: [
                               '.questions .right:after{',
                               `background:${`url(${encodeURI(correctAnswerIcon)})`} no-repeat left top;`,
                               '}'
                              ].join('\n')
                            }}> 
                          </style> : null
                        }
                        <span className="oops">OOPS!</span>
                        <span className="correct-ans" />
                        <input id={answer.AnswerID} checked={answer.AnswerID === selectedAnswer} className="radio" name="name" type="radio" disabled={isSubmitted} />
                        <button className={`button-style`} onClick={() => this.setAnswer(answer)} disabled={isSubmitted}>
                          <span className="alpha">{alpha[index]}. </span> {answer.Option}
                        </button>
                      </li>
                    )
                  })}
                </ul>
                {(item.Image && !imageError) ? <img className="question-img" src={GlobalConfig.getApiUrlFromRoot(`/${item.Image}`)} onError={this.onError} />: null}
                </div>
            </CSSTransition>
            )
          })}
        </div>
        {

          lastQuestion && get(answer, "submitted") ? (<button
          className="btn"
          // onClick={this.goToNext}
          onClick={()=>this.goToNext()}
          >
            Submit Score
          </button>)
          :
          (<button
          className="btn"
          onClick={()=> this.onSubmit(QuestionId)}
          disabled={!answer}>
            {
              get(answer, "submitted") ? "Next": "Submit"
            }
         </button>)
        }
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={1000 * 60 * 2} />
          <div className={`modal-container ${modalVisible ? 'show' : ''}`}>
            <div className="modal-overlay"></div>
            <div className="modal-popup">
              <div className="wrapper leaderboard-container">
                <h2>LEADERBOARD</h2>
                <ul className="leaderboard">
                  {newLeaderBoard.map((data, index) => {
                    return (
                      <li key={index}><span>{data.UsrName.substr(0, 3)}</span><span>{data.Score}</span></li>
                    )
                  })}
                </ul>
                <div className="text-center">
                  <button className="btn" onClick={this.startNewGame}>Start new game</button>
                </div>
                <div className="text-center">
                  <button className="btn" onClick={this.closeModal}>Resume game</button>
                </div>
              </div>
            </div>
          </div>
      </section>
      </LoaderOverlay>
    )
  }
}

Game.propTypes = {
  questionsBank: PropTypes.arrayOf(PropTypes.shape({})),
  activeGame: PropTypes.shape({}),
  history: PropTypes.shape({}),
  leaderBoardList: PropTypes.arrayOf(PropTypes.shape({}))
};

Game.defaultProps = {
  questionsBank: [],
  activeGame: {},
  history: {},
  leaderBoardList: []
};

function mapStateToProps(state) {
  return {
    questionsBank: state.commonReducer.questionsBank,
    activeGame: state.commonReducer.activeGame,
    leaderBoardList: state.commonReducer.leaderBoardList
  };
}

export default connect(mapStateToProps)(withRouter(Game));
