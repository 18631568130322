import { isArray } from "lodash";
import moment from "moment";
import { GAME_ACTION, QUESTION_ACTION, STATUS } from "../constant";


const initState = {
  gamesList: [],
  gameListApiStatus: null,
  activeGame: null,
  questionsBank: {},
  questionApiStatus: null,
  leaderBoardList: [],
  leaderBoardListStatus: null
};

export default function common(state = initState, action) {
  switch (action.type) {
    case GAME_ACTION.GET_GAME_DETAIL_START:
      return { ...state, gameListApiStatus: STATUS.LOADING };
    case GAME_ACTION.GET_GAME_DETAIL_SUCCESS: {
      const { payload } = action;
      if(!isArray(payload)){
        return {...state, gameListApiStatus: STATUS.SUCCESS,
          activeGame: [...state.gamesList].find(
            game => moment().isSameOrAfter(moment(game.StartDateTime), "day") && moment().isSameOrBefore(moment(game.EndDateTime), "day")
          ),
        };
      }
      return {
        ...state,
        gamesList: payload,
        activeGame: [...payload].find(
          game => moment().isSameOrAfter(moment(game.StartDateTime), "day") && moment().isSameOrBefore(moment(game.EndDateTime), "day")
        ),
        gameListApiStatus: STATUS.SUCCESS
      };
    }
    case GAME_ACTION.GET_LEADERBOARD_DETAIL_START:
      return { ...state, leaderBoardListStatus: STATUS.LOADING };
    case GAME_ACTION.GET_LEADERBOARD_DETAIL_SUCCESS: {
      return {
        ...state,
        leaderBoardList: isArray(action.payload) ? action.payload: [],
        leaderBoardListStatus: STATUS.SUCCESS
      };
    }
    case GAME_ACTION.CHANGE_LEADERBOARD_API_STATUS:
      return { ...state, leaderBoardListStatus: null };
    case QUESTION_ACTION.GET_QUESTION_BANK_START:
      return { ...state, questionApiStatus: STATUS.LOADING };
    case QUESTION_ACTION.GET_QUESTION_BANK_SUCCESS: {
      const questionsBank = {};
      const { payload } = action;
      if(!isArray(payload)){
        return {...state, questionApiStatus: STATUS.SUCCESS};
      }
      (payload || []).forEach((question) => {
        if(!questionsBank[question.DifficultyLevelId]){
          questionsBank[question.DifficultyLevelId] = [question]
        }
        else{
          questionsBank[question.DifficultyLevelId] = [...questionsBank[question.DifficultyLevelId], question]
        }
      })
      return {
        ...state,
        questionsBank,
        questionApiStatus: STATUS.SUCCESS
      };
    }
    default:
      return state;
  }
}
