import GlobalConfig from "../globalConfig";

export const getLocationParams = (data = {}) => ({
  method: "POST",
  headers: GlobalConfig.getHeaders(["JSON"]),
  url: GlobalConfig.getApiUrlFromRoot("/PlatformMasters/GetAllPlatforms"),
  data: { ...data }
});

export const getGameDetail = (data = {}) => ({
  method: "GET",
  headers: GlobalConfig.getHeaders(["JSON"]),
  url: GlobalConfig.getApiUrlFromRoot("/DataManagement/GetAllGameByPlatform"),
  data: { ...data }
});

export const getQuestionBank = (data = {}) => ({
  method: "GET",
  headers: GlobalConfig.getHeaders(["JSON"]),
  url: GlobalConfig.getApiUrlFromRoot("/DataManagement/GetGameAllQuestion"),
  data: { ...data }
});

export const postPlayerScore = (data = {}) => ({
  method: "POST",
  headers: GlobalConfig.getHeaders(["JSON"]),
  url: GlobalConfig.getApiUrlFromRoot("/DataManagement/SyncUserData"),
  data: { ...data }
});

export const getLeaderBoardParams = (data = {}) => ({
  method: "GET",
  headers: GlobalConfig.getHeaders(["JSON"]),
  url: GlobalConfig.getApiUrlFromRoot("/DataManagement/GetLeaderBoardForGamingApp"),
  data: { ...data }
});

export const postSocialShare = (data = {}) => ({
  method: "POST",
  headers: GlobalConfig.getHeaders(["JSON"]),
  url: GlobalConfig.getApiUrlFromRoot("/DataManagement/SyncUserSocialShareLogs"),
  data: { ...data }
});

export const shareEmail = (data = {}) => ({
  method: "POST",
  headers: GlobalConfig.getHeaders(["JSON"]),
  url: GlobalConfig.getApiUrlFromRoot("/MailSender/SendEmail"),
  data: { ...data }
});
