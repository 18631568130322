import React from "react";
import { ReactComponent as Loader } from "../assets/images/spinner-loader.svg";

export const LoaderOverlay = ({ className, children, loading }) => {
  return (
    <div className={`${className} ${loading ? "show-loader" : ""}`}>
      <div className="overlay-loader">
        <Loader />
      </div>
      <div>{children}</div>
    </div>
  );
};
