import moment from "moment";
import { openDB } from "idb";

export const serialize = obj => {
  const str = [];
  const keys = Object.keys(obj);
  keys.forEach(key => {
    str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  });
  return str.join("&");
};

/* istanbul ignore next */
export const setLocalstorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/* istanbul ignore next */
export const getLocalstorage = key => {
  return JSON.parse(localStorage.getItem(`${key}`) || null);
};

/* istanbul ignore next */
export const removeItemFromLocalStorage = key => {
  localStorage.removeItem(key);
};

// validate email
export function isValidateEmail(value) {
  // const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/gi;
  // const regex = /^\w+[!#$%&'*+-/=?^_`{|}~]?@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/gi;
  const regex = /^[_A-Za-z0-9-\\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/gi;
  return regex.test(value);
}

// only user can type numbers
export function onlyNumbers(value) {
  return value.replace(/[^0-9]/gi, "");
}

export function dateTimeStamp(value) {
  return new Date().getTime();
}

export async function readFromIndexDB (){
  const dbPromise = await openDB("users");
  return await dbPromise.getAll("scores");
}

export async function clearAllData (){
  const dbPromise = await openDB("users");
  return await dbPromise.clear("scores");
}

export const dateFormatMapping = date => moment(date).toISOString();
